import React from "react";
import {Row, Col} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import {faPlus, faMinus, faEdit, faXmark} from "@fortawesome/free-solid-svg-icons";
import SetDataWithSpeechBubble from "./SetDataWithSpeechBubble";
import ButtonSpeechBubble from "./SetButtonWithSpeechBubble";
import moment from "moment";

export class EditAccountInfo extends Component {
    validateTelNumber(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateTelNumber(param);
        }
        return validation_flag;
    }

    validatePassword(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validatePassword(param);
        }
        return validation_flag;
    }

    validateMail(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateMail(param);
        }
        return validation_flag;
    }

    subUserPlus() {
        let subUsers = this.props.state.subUsers;
        let oldSubUsers = this.props.state.oldSubUsers;
        // 8文字、数字および小英字が1-4入っているパスワードを生成
        let intNum = Math.floor(Math.random() * (3 - 1)) + 1;
        let sNum = Math.floor(Math.random() * (4 - 1)) + 1;
        let S = "abcdefghijklmnopqrstuvwxyz";
        let B = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let N = 8 - intNum - sNum;
        let tmp = Array.from(crypto.getRandomValues(new Uint8Array(N))).map((n)=>S[n%S.length]).join('');
        tmp += Array.from(crypto.getRandomValues(new Uint8Array(sNum))).map((n)=>B[n%B.length]).join('');
        for (let i = 0; i < intNum; i++) {
            tmp += String(Math.floor(Math.random() * 9));
        }
        let obj = {};
        for (let i = 0; i < tmp.length; i++) {
            // ランダムな数値を生成する ※文字数より少ないと永久ループする
            let rand = Math.floor(Math.random() * 100);
            // 同じキー名がないか判別する
            if (!obj[rand]) {
                // 同じキー名がない場合にobjに追加する
                obj[rand] = tmp[i];
            } else {
                // 同じキー名ある場合、やり直す
                i--;
            }
        }
        let password = Object.values(obj).join('');
        N = 3;
        for (let i = 0; i <= 30; i++) {
            let tmpId = Array.from(crypto.getRandomValues(new Uint8Array(N))).map((n)=>S[n%S.length]).join('');
            if (subUsers.every(item => item.sub_user_id !== this.props.state.user_id + tmpId)
                && oldSubUsers.every(item => item.sub_user_id !== this.props.state.user_id + tmpId)) {
                // 新しく作った分と削除した分で重複しないように
                subUsers.push({sub_user_id: this.props.state.user_id + tmpId, password, memo: "", new: true, edit: true});
                this.props.propSetState({subUsers});
                return;
            } else if (i === 30) {
                return console.error("sub_user_id create error.");
            }
        }
    }

    subUserEdit(e, type, index) {
        let subUsers = this.props.state.subUsers;
        switch (type) {
            case "sub_user_memo":
                subUsers[index].memo = e.target.value;
                break;
            case "sub_user_password":
                subUsers[index].password = e.target.value;
                break;
            default:
                break;
        }
        this.props.propSetState({subUsers});
    }

    subUserMinus(index) {
        let subUsers = this.props.state.subUsers;
        subUsers.splice(index, 1);
        this.props.propSetState({subUsers});
    }

    subUserPasswordEdit(index, flag) {
        let subUsers = this.props.state.subUsers;
        subUsers[index].edit = flag;
        if (!flag) {
            subUsers[index].password = "";
        }
        this.props.propSetState({subUsers});
    }

    subUsersGetEdit() {
        return this.props.state.subUsers.map((row,index) => {
            return <Row className = "sub_user_row">
                <ButtonSpeechBubble
                    key = {`sub_user_minus-button-${index}`}
                    size="sm"
                    className="sub_user_minus-button"
                    DisplayIcon={faMinus}
                    speechBubble={this.props.langText.Body.AccountSubUserMinus}
                    onClick={() => this.subUserMinus(index)}
                />
                <Col className = "sub_user">
                    <InlineForm
                        key = {`sub_user-${index}`}
                        controlId = {`sub_user-${index}`}
                        value = {row.sub_user_id}
                        readOnly
                    />
                </Col>
                <Col className = "sub_user_memo">
                    <InlineForm
                        key = {`sub_user_memo-${index}`}
                        controlId = {`sub_user_memo-${index}`}
                        type = "text"
                        value = {row.memo}
                        onChange = {e => this.subUserEdit(e, "sub_user_memo", index)}
                    />
                </Col>
                <Col className = "sub_user_password">
                    {row.edit ? !row.new ? <div className="sub_user_password_change">
                                <ButtonSpeechBubble
                                    key = {`sub_user_password_change_cancel-${index}`}
                                    size="sm"
                                    className="sub_user_minus-button"
                                    DisplayIcon={faXmark}
                                    speechBubble={this.props.langText.Body.PasswordChangeCancel}
                                    onClick={() => this.subUserPasswordEdit(index, false)}
                                />
                                <InlineForm
                                    key = {`sub_user_password-${index}`}
                                    controlId = {`sub_user_password-${index}`}
                                    type = "text"
                                    value = {row.password}
                                    placeholder = {this.props.langText.Body.PasswordPlaceholder}
                                    isInvalid = {(!row.new && !this.validatePassword(this.props.state.subUsers[index].password,true))
                                        || (row.new && !this.validatePassword(this.props.state.subUsers[index].password,false)) }
                                    onChange = {e => this.subUserEdit(e, "sub_user_password", index)}/>
                            </div> :
                            <InlineForm
                                key = {`sub_user_password-${index}`}
                                controlId = {`sub_user_password-${index}`}
                                type = "text"
                                value = {row.password}
                                placeholder = {this.props.langText.Body.PasswordPlaceholder}
                                isInvalid = {(!row.new && !this.validatePassword(this.props.state.subUsers[index].password,true))
                                    || (row.new && !this.validatePassword(this.props.state.subUsers[index].password,false)) }
                                onChange = {e => this.subUserEdit(e, "sub_user_password", index)}/>
                        : <ButtonSpeechBubble
                            key = {"sub_user_password_edit"}
                            size="sm"
                            className="sub_user_plus-button"
                            DisplayIcon={faEdit}
                            speechBubble={this.props.langText.Body.AccountInfoList.password_change}
                            onClick={() => this.subUserPasswordEdit(index, true)}
                        />
                    }
                </Col>
            </Row>
        });
    }

    render() {
        let columns = [
            {
                Header: this.props.langText.Body.Item,
                accessor: 'account_info_item',
                width: this.props.boardWidth.xsmall
            }, {
                Header: this.props.langText.Body.Information,
                accessor: 'account_info_data',
                width: this.props.boardWidth.xlarge
            }
        ]
        let returnValue =[];

        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.user_id,
            account_info_data: <InlineForm
                key="user_id"
                controlId="user_id"
                readOnly
                children={this.props.state.user_id}
                value = {this.props.state.user_id}
            />,
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.name,
            account_info_data: <InlineForm
                key="user_id"
                controlId="user_id"
                readOnly
            >
                <Row>
                    <Col className = "user_name">
                        <InlineForm
                            key = "family_name"
                            controlId = "family_name"
                            label = {this.props.langText.Body.FamilyName}
                            type = "text"
                            value = {this.props.state.family_name}
                            onChange = {e => this.props.onTextChange(e, 'family_name')}
                        />
                    </Col>
                    <Col className = "user_name">
                        <InlineForm
                            key = "first_name"
                            controlId = "first_name"
                            label = {this.props.langText.Body.FirstName}
                            type = "text"
                            value = {this.props.state.first_name}
                            onChange = {e => this.props.onTextChange(e, 'first_name')}
                        />
                    </Col>
                </Row>
            </InlineForm>
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.mail_address,
            account_info_data:<InlineForm
                key = "mail_address"
                type = "mail"
                value = {this.props.state.mail_address}
                onChange = {e => this.props.onTextChange(e, 'mail_address')}
                isInvalid = {!this.validateMail(this.props.state.mail_address)}
            />
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.tel_number,
            account_info_data: <InlineForm
                key = "tel_number"
                type = "text"
                value = {this.props.state.tel_number}
                onChange = {e => this.props.onTextChange(e, 'tel_number')}
                isInvalid = {!this.validateTelNumber(this.props.state.tel_number,true)}
            />
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.password_change,
            account_info_data: this.props.state.password_change ?
                <div>
                    <Row className = "sub_user_row">
                        <Col className = "password">
                            <InlineForm
                                key = "password"
                                controlId = "password"
                                type = "password"
                                value = {this.props.state.password}
                                onChange = {e => this.props.onTextChange(e, "password")}
                                label = {this.props.langText.Body.Password}
                                isInvalid = {!this.validatePassword(this.props.state.password,true)
                                || this.props.state.password_new? !this.validatePassword(this.props.state.password): false}
                            />
                        </Col>
                        <Col className = "password">
                            <InlineForm
                                key = "password_new"
                                controlId = "password_new"
                                type = "password"
                                value = {this.props.state.password_new}
                                onChange = {e => this.props.onTextChange(e, "password_new")}
                                label = {this.props.langText.Body.PasswordNew}
                                isInvalid = {!this.validatePassword(this.props.state.password_new,true)}
                            />
                        </Col>
                        <Col className = "password">
                            <InlineForm
                                key = "password_new_confirm"
                                controlId = "password_new_confirm"
                                type = "password"
                                value = {this.props.state.password_new_confirm}
                                onChange = {e => this.props.onTextChange(e, "password_new_confirm")}
                                label = {this.props.langText.Body.PasswordNewRe}
                                isInvalid = {!this.validatePassword(this.props.state.password_new_confirm,true)
                                    || this.props.state.password_new !== this.props.state.password_new_confirm}
                            />
                        </Col>

                    </Row>
                    <ButtonSpeechBubble
                        key = {"password_changed"}
                        size="sm"
                        className="sub_user_minus-button"
                        DisplayIcon={faXmark}
                        speechBubble={this.props.langText.Body.PasswordChangeCancel}
                        onClick={() => this.props.propSetState({
                            password_change: false,
                            password_new_confirm: null,
                            password_new: null,
                            password: null
                        })}
                    />
                </div>
                : <ButtonSpeechBubble
                    key = {"password_changed"}
                    size="sm"
                    className="sub_user_plus-button"
                    DisplayIcon={faEdit}
                    speechBubble={this.props.langText.Body.AccountInfoList.password_change}
                    onClick={() => this.props.propSetState({password_change: true})}
                />

        });
        returnValue.push({
            account_info_item: <SetDataWithSpeechBubble
                displayData = {this.props.langText.Body.AccountInfoList.sub_users}
                speechBubbleData = {this.props.langText.Body.AccountSubUserExp}
                infoIconDisplayFlag = {true}
                infoIconType = {null}
                infoIconClassName = "modal-info-icon"
                infoIconColor = "silver"
                infoIconSize = "lg"/>,
            account_info_data: <InlineForm
                key="sub_user_id"
                controlId="sub_user_id"
                readOnly
            >
                {this.props.state.subUsers.length > 0 ? <Row className="sub_user_row_head">
                    <div className="sub_user_minus-button-space">
                    </div>
                    <Col className="sub_user">
                        {this.props.langText.Body.AccountSubUserId}
                    </Col>
                    <Col className="sub_user_memo">
                        {this.props.langText.Body.AccountMemo}
                    </Col>
                    <Col className="sub_user_password">
                        {this.props.langText.Body.Password}
                    </Col>
                </Row> : null}
                {this.subUsersGetEdit()}
                {this.props.state.subUsers.length > 4 ? null : <Row>
                    <ButtonSpeechBubble
                        key = {"sub_user"}
                        size="sm"
                        className="sub_user_plus-button"
                        DisplayIcon={faPlus}
                        speechBubble={this.props.langText.Body.AccountSubUserPlus}
                        onClick={() => this.subUserPlus(this.props.state.subUsers.length)}
                    />
                </Row>}
            </InlineForm>
        });
        let recorded_time_arr = [];
        for (let i = 0; i < 3; i++) {
            let l = this.props.state.ht60_total.find(row => row.month === moment().utc().add(9, "h").add(-i, "M").format("YYYY-MM"));
            if (l) {
                recorded_time_arr.push(this.getFormatSec(l.sum));
            } else {
                recorded_time_arr.push(this.getFormatSec(0));
            }
        }
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.recorded_time,
            account_info_data: <InlineForm
                key="recorded_time_3month"
                controlId="recorded_time_3month"
                readOnly
            >
                <Row className="recorded_time_3month_row_head">
                    <Col className="recorded_time_3month_2month_ago">
                        <InlineForm
                            key="2month_ago"
                            controlId="2month_ago"
                            readOnly
                            label = {this.props.langText.Body.AccountRecordedTime2monthAgo}
                            children={recorded_time_arr[2]}
                        />
                    </Col>
                    <Col className="recorded_time_3month_1month_ago">
                        <InlineForm
                            key="1month_ago"
                            controlId="1month_ago"
                            readOnly
                            label = {this.props.langText.Body.AccountRecordedTime1monthAgo}
                            children={recorded_time_arr[1]}
                        />
                    </Col>
                    <Col className="recorded_time_3month_this_month">
                        <InlineForm
                            key="this_month"
                            controlId="this_month"
                            readOnly
                            label = {this.props.langText.Body.AccountRecordedTimeThisMonth}
                            children={recorded_time_arr[0]}
                        />
                    </Col>
                </Row>
            </InlineForm>,
        });

        return (
            <CommonTable
                columns = {columns}
                data = {returnValue}
                className = "none-overflow"/>
        );
    }
}

export class ViewAccountInfo extends Component {

    subUsersGetView() {
        return this.props.state.subUsers.map((row,index) => {
            return <Row className = "sub_user_row">
                <Col className = "sub_user">
                    <InlineForm
                        key = {`sub_user_id-${index}`}
                        controlId = {`sub_user_id-${index}`}
                        value={row.sub_user_id}
                        readOnly
                    />
                </Col>
                <Col className = "sub_user_memo">
                    <InlineForm
                        key = {`sub_user_memo-${index}`}
                        controlId = {`sub_user_memo-${index}`}
                        type = "text"
                        value = {row.memo}
                        readOnly
                    />
                </Col>
                <Col className = "sub_user_password">
                    {row.password ? <InlineForm
                            key = {`sub_user_password-${index}`}
                            controlId = {`sub_user_password-${index}`}
                            type = "text"
                            value = {row.password}
                            readOnly/>
                        : <InlineForm
                            key = {`sub_user_password-${index}`}
                            controlId = {`sub_user_password-${index}`}
                            type = "text"
                            value = {this.props.langText.Body.AccountNoChange}
                            readOnly/>
                    }
                </Col>
            </Row>
        });
    }

    render() {
        let columns = [
            {
                Header: this.props.langText.Body.Item,
                accessor: 'account_info_item',
                width: this.props.boardWidth.xsmall
            }, {
                Header: this.props.langText.Body.Information,
                accessor: 'account_info_data',
                width: this.props.boardWidth.xlarge
            }
        ]
        let returnValue =[];
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.user_id,
            account_info_data: <InlineForm
                key="user_id"
                controlId="user_id"
                readOnly
                children={this.props.state.user_id||' '}
            />,
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.name,
            account_info_data: <InlineForm
                key="user_id"
                controlId="user_id"
                readOnly
            >
                <Row>
                    <Col className = "user_name">
                        <InlineForm
                            key = "family_name"
                            controlId = "family_name"
                            label = {this.props.langText.Body.FamilyName}
                            readOnly
                            value = {this.props.state.family_name||' '}
                        />
                    </Col>
                    <Col className = "user_name">
                        <InlineForm
                            key = "first_name"
                            controlId = "first_name"
                            label = {this.props.langText.Body.FirstName}
                            readOnly
                            value = {this.props.state.first_name||' '}
                        />
                    </Col>
                </Row>
            </InlineForm>,
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.mail_address,
            account_info_data: <InlineForm
                key = "mail_address"
                type = "mail"
                readOnly
                children = {this.props.state.mail_address||' '}
            />,
        });
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.tel_number,
            account_info_data: <InlineForm
                key = "tel_number"
                type = "text"
                readOnly
                children = {this.props.state.tel_number||' '}
            />,
        });
        returnValue.push(
            {
                account_info_item: this.props.langText.Body.AccountInfoList.password_change,
                account_info_data: <InlineForm
                    key = "password"
                    controlId = "password"
                    readOnly
                    children = {this.props.state.password_new ? this.props.langText.Body.True : this.props.langText.Body.False}
                />,
            }
        );
        returnValue.push({
            account_info_item: this.props.langText.Body.AccountInfoList.sub_users,
            account_info_data: <InlineForm
                key="sub_user_id"
                controlId="sub_user_id"
                readOnly
            >
                {this.props.state.subUsers.length > 0 ? <Row className="sub_user_row_head">
                    <div className="sub_user_minus-button-space">
                    </div>
                    <Col className="sub_user">
                        {this.props.langText.Body.AccountSubUserId}
                    </Col>
                    <Col className="sub_user_memo">
                        {this.props.langText.Body.AccountMemo}
                    </Col>
                    <Col className="sub_user_password">
                        {this.props.langText.Body.Password}
                    </Col>
                </Row> : this.props.langText.Body.AccountNoSubUsers}
                {this.subUsersGetView()}
            </InlineForm>,
        });

        return (
            <CommonTable
                columns = {columns}
                data = {returnValue}
                className = "none-overflow"
                loading={this.props.state.loading}/>
        );
    }
}

