export default {
    Wait: "少々お待ちください。",
    // モーダルタイトル
    Title: "タイトル",
    InsertTitle:      "新規登録",
    InsertBatchTitle: "一括登録",
    UpdateTitle:      "編集",
    DeleteTitle:      "削除",
    CopyTitle:      "コピー",
    ReadTitle: "閲覧",
    Version: "バージョン",
    ErrorTitle: "エラー内容",
    NewInsert: "新規作成",

    // ボタン
    Insert: "登録",
    Add:    "追加",
    Update: "更新",
    BatchDelete: "一括削除",
    Delete: "削除",
    Cancel: "キャンセル",
    Set:    "設定",
    Check: "確認",
    Upload: "アップロード",
    Submit: "送信",
    CodeSubmit: "認証コード送信",
    Verify: "ユーザー認証",
    ResetPassword: "パスワード再設定",
    LogIn: "ログイン",
    LogGingIn :"ログイン中。。。",
    LogOut :"ログアウト",
    Search: "検索",
    Copy: "コピー",
    Read: "閲覧",
    Close: "閉じる",
    Miss_Inbound: "未着信",
    Save: "保存",
    Insert2: "挿入",
    AdSet: "詳細設定",
    InsertTelNo: "番号追加",
    Back: "戻る",
    Today: "今日",
    KeywordSearch: "ワード検索",

    // 一般項目
    Id:             "ID",
    Control:        "操作",
    Memo:           "メモ",
    CompanyName:    "会社名",
    DepartmentName: "拠点名",
    Lang: "言語",
    Text: "テキスト",
    Number: "番号",
    Name: "名前",
    True: "あり",
    False: "なし",
    MailAddress: "メールアドレス",
    PasswordPlaceholder: "英数字混在8文字以上",
    PasswordChange: "パスワード変更",
    PasswordChangeCancel: "パスワード変更をキャンセル",
    // 特定項目
    AllDepartment: "全拠点",
    AllFloor: "全フロア",
    Audio: "音声",
    AudioInfo: "音声ファイル設定",
    AudioSetting: "音声ファイル設定",
    AudioName: "音声名",
    Auth: "権限",
    AutoCreate:	"自動生成",
    BackNumber: "裏番号",
    Created: "登録日",
    CreatedDateTime: "登録日時",
    DateFormat: "YYYY/MM/DD",
    DisplayNumber: "表示番号",
    Download: "ダウンロード",
    UserIdLogIn: "ユーザーID",
    EntryUserName: "登録者",
    FirstNameCc: "姓",
    FirstNameKana: "セイ",
    FloorSetting:"フロア設定",
    LastNameCc:	"名",
    LastNameKana: "メイ",
    Modified: "更新日",
    Password: "パスワード",
    PasswordLogIn: "Password",
    PasswordNew: "新パスワード",
    PasswordNewRe: "新パスワード(再入力)",
    PasswordOld: "旧パスワード",
    PasswordRe:	"パスワード(再入力)",
    Telno: "電話番号",
    RequiredTelno: "電話番号（必須）",
    UpdateUserName:	"更新者",
    UserInfo: "ユーザー情報",
    UserInsert: "ユーザー登録",
    UserName: "ユーザー名",
    UserNameCc: "ユーザー名 (漢字)",
    UserNameKana: "ユーザー名 (カナ)",
    VerificationCode: "認証コード",
    //デバイス管理
    SerialNumber: "シリアルナンバー",
    LatestActive: "最終アクティブ",
    OwnerUserId: "所有ユーザーID",
    OwnerUserName: "所有ユーザー名",
    DeviceInfo: "デバイス情報",

    //アカウント管理
    Information: "情報",
    Item: "項目",
    AccountInfoList: {
        user_id : "ユーザーID",
        name : "名前",
        mail_address : "メールアドレス",
        tel_number : "連絡先電話番号",
        password_change : "パスワード変更",
        sub_users: "サブユーザー",
        recorded_time: "録音音声時間"
    },
    AccountSubUserId: "サブユーザーID",
    AccountMemo: "メモ",
    AccountNoChange: "変更なし",
    AccountNoSubUsers: "サブユーザーなし",
    AccountSubUserExp: "録音音声の再生、ダウンロード及び削除が可能なサブユーザーを5つまで管理できます。",
    AccountSubUserPlus: "サブユーザーを追加",
    AccountSubUserMinus: "サブユーザーを削除",
    AccountRecordedTime2monthAgo: "先々月",
    AccountRecordedTime1monthAgo: "先月",
    AccountRecordedTimeThisMonth: "当月",
    AccountCancelForm: "解約申込",

    // 録音音声一覧
    PlayBack: "録音再生",
    Voice: "録音音声",
    VoiceInfo: "録音音声情報",
    ExternalNumber: "外線番号",
    DestTelNumber: "相手先番号",
    InOutType: "発着信",
    AllType: "全て",
    Incoming: "着信",
    Outgoing: "発信",
    IncomingIcon: "◀",
    OutgoingIcon: "▶",
    NumInfo: "番号情報",
    CallDate: "通話日時",
    CallInfo: "通話情報",
    CallCategories: "通話分類",
    CallAnalysisData: "通話概要",
    CallOverview: "通話要約",
    BillSec: "通話時間",
    Date: "日付",
    StartDate: "開始日",
    StartTime: "開始時間",
    EndTime: "終了時間",
    DateTime: "日時",
    StartDateTime: "開始日時",
    EndDateTime: "終了日時",
    DetailConditions: "詳細条件",
    CustomerAdd: "電話帳登録",
    CustomerEdit: "電話帳更新",
    CustomerDelete: "電話帳削除",
    CustomerName: "顧客名",
    TimeMin: "時分",
    Latest: "最新",
    AudioFileDownload: "音声ファイルダウンロード",
    audio2text: "AI録音文字起こし",
    audio2textComplete: "AI録音文字起こし済み",
    audio2textFailed: "AI録音文字起こしに失敗しました。管理者にお問い合わせください。",
    audio2textWorking: "AI録音文字起こし処理中",
    audio2textNotFound: "AI録音文字起こし内容がありません。",
    audio2textFreeHoursMessage: "AI録音文字起こし無料枠 {0}時間 / {1}時間",
    audio2textFreeHours: "毎月{0}時間の無料枠",
    audio2textCallTimeToAccountMenu: "無料枠超過分は{0}時間ごとに{1}円（税抜）の料金が発生します",
    audio2textCallRecAcePlusFree: "※RecACE plusご利用ユーザーは追加料金無し",


    //ユーザー管理
    OwnDevice: "所有デバイス",
    Device: "デバイス",
    UserId: "ユーザーID",
    FamilyName: "姓",
    FirstName:	"名",
    Type: "種別",
    Business: "法人",
    Customer: "個人",
    VoicesExpire: "録音データ保持期限",
    HalfYear: "半年",
    Year: "年",
    Day: "日",
    Options: "オプション",
    customer_infos: "電話帳機能",

    // 権限管理
    Permission: "権限",
    BasePermissionName: "基本権限",
    PermissionName: "権限名",
    PermissionMemo:"メモ",
    UserCount: "ユーザー数",
    PermissionInfo: "権限情報",
    PermissionFeature: "機能",
    PermissionLevel: "レベル",
    permissionDetail: "権限詳細",
    PermissionContent: "権限内容",
    PermissionScope: "権限範囲",
    PermissionAll: "全権限",
    PermissionRead: "閲覧",
    PermissionCreate: "新規登録",
    PermissionEdit: "編集",
    PermissionDelete: "削除",
    PermissionExport: "出力",
    PermissionPlayback: "録音再生",
    PermissionContentList: {
        Record: "録音一覧",
        User: "ユーザ管理",
        Device: "デバイス管理",
        Permission: "権限管理",
    },
    PermissionLevelList: {
        user: "一般",
        company: "会社内",
        system: "システム内"
    },
    chargedOption : "有料オプション",
    callRecordOption : "通話録音オプション",

    Count: "件"
}

