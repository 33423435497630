export default {
    // 権限管理
    User_username: 256,
    User_mail_address: 256,
    User_tel_number: 256,
    Permission_permissionName: 256,
    Permission_permissionMemo: 256,
    
    // 録音音声
    Call_Overview_Display: 20
}
