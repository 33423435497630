import React from "react";
import {Row, Col} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";

/**
 * シリアルナンバービューアイテム
 * state.serial_numberが必要
 */
export class ViewSerialNumber extends Component {
    render() {
        return(
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.SerialNumber}
                readOnly
                children={this.props.state.serial_number}
            />
        )
    }
}

/**
 * ユーザーIDビューアイテム
 * state.user_idが必要
 */
export class ViewUserId extends Component {
    render() {
        return(
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.UserId}
                readOnly
                children={this.props.state.owner_user_id}
            />
        )
    }
}

/**
 * ユーザー名ビューアイテム
 * state.family_name, state.first_nameが必要
 */
export class ViewUserName extends Component {
    render() {
        return (
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.Name}
                readOnly
            >
                <Row>
                    <Col className = "user_name">
                        <InlineForm
                            key = "family_name"
                            controlId = "family_name"
                            label = {this.props.langText.Body.FamilyName}
                            children = {this.props.state.owner_family_name}
                        />
                    </Col>
                    <Col className = "user_name">
                        <InlineForm
                            key = "first_name"
                            controlId = "first_name"
                            label = {this.props.langText.Body.FirstName}
                            type = "text"
                            children = {this.props.state.owner_first_name}
                        />
                    </Col>
                </Row>
            </InlineForm>
        );
    }
}

/**
 * 登録日時ビューアイテム
 * state.createdが必要
 */
export class ViewCreated extends Component {
    render() {
        return(
            <InlineForm
                key="created"
                controlId="created"
                label={this.props.langText.Body.CreatedDateTime}
                readOnly
                children={
                    this.props.getMomentTime({
                        format: this.props.langText.Time.TimeFormat,
                        date: this.props.state.created
                    })
                }
            />
        )
    }
}

export class ViewLastestsActive extends Component {
    render() {
        return(
            <InlineForm
                key="created"
                controlId="created"
                label={this.props.langText.Body.LatestActive}
                readOnly
                children={
                    this.props.state.lastest_active?
                    this.props.getMomentTime({
                        format: this.props.langText.Time.TimeFormat,
                        date: this.props.state.lastest_active
                    }):
                    '---'
                }
            />
        )
    }
}

/**
 * 削除項目ビューアイテム
 * state.selectedDataが必要
 */
export class ViewDeleteDeviceList extends Component {
    render(){
        return(
            <>
            {this.props.state.isOwnedDevice&&
            <p className="validation-message">
                {this.props.state.isOwnedDevice}{this.props.langText.Message.Device_IsOwnedDeviceError}
            </p>}
            <CommonTable
                showPagination = {true}
                defaultPageSize = {10}
                columns = {[{
                    Header :this.props.langText.Body.SerialNumber,
                    accessor: "serial_number",
                },
                {
                    Header :this.props.langText.Body.Memo,
                    accessor: "description",
                }]}
                data = {this.props.state.selectedData}
            />
            </>
        );
    }
}


/**
 * メモ編集アイテム
 * state.descriptionが必要
 */
export class EditDescription extends Component {
    render() {
        return(
            <InlineForm
                key = "description"
                controlId = "description"
                type = "text"
                label = {this.props.langText.Body.Memo}
                value = {this.props.state.description}
                onChange = {e => this.props.onTextChange(e, "description")}
            />
        )
    }
}

/**
 * メモ編集アイテム
 * state.descriptionが必要
 */
export class ViewDescription extends Component {
    render() {
        return(
            <InlineForm
                key="description"
                controlId="description"
                label={this.props.langText.Body.Memo}
                readOnly
                children={
                    this.props.state.description
                        ? this.props.state.description
                        : '---'
                }
            />
        )
    }
}