import React, {Component} from "react";
import {Button, Modal, Card, Form, Row, Col} from "react-bootstrap";
import "../Elements/Modal/Modal.css";
import ReactLoading from "react-loading";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import "../Elements/FromGroup/form_select.css"
import InlineForm from "../Elements/FromGroup/InlineForm";
export default class RecordPlaybackModal extends Component {
    // get modal title
    getTitle = () => {
        let title = null;
        let okBtnDisplayFlg = true;

        switch (this.props.state.modalType) {
            case "addCustomer":
                title = this.props.langText.Body.CustomerAdd;
                break;
            case "editCustomer":
                title = this.props.langText.Body.CustomerEdit;
                break;
            default:
        }

        return {title, okBtnDisplayFlg};
    }

    getDeleteBtn = () => {
        let result = this.getTitle("ok");
        if (result.okBtnDisplayFlg) {
            return (
                <Button
                    className = 'customerDeleteBtn'
                    variant = {"danger"}
                    onClick = {()=> this.props.onClickHandle("deleteCustomer")}
                >
                    {this.props.langText.Body.CustomerDelete}
                </Button>
            );
        } else {
            return null;
        }
    }

    getOkBtn = () => {
        let result = this.getTitle("ok");
        if (result.okBtnDisplayFlg) {
            return (
                <Button
                    className = 'ok-execute'
                    onClick = {this.props.onClickHandle}
                    variant = {this.props.state.modalType !== "delete" ? "primary" : "danger"}
                    disabled = {!this.props.validationHandle(this.props.state.modalType)}
                    >
                    {result.title}
                </Button>
            );
        } else {
            return null;
        }
    }

    getCancelBtn = () => {
        return (
            <Button
                className = 'cancel-execute'
                onClick = {() => this.props.propSetState({show: false})}
                variant = 'outline-secondary'
            >
                {this.props.langText.Body.Cancel}
            </Button>
        );
    }

    getXBtn = () => {
        return (
            <Button
                className = 'x-execute'
                onClick = {() => this.props.propSetState({show: false})}
            >
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        );
    }

    modalLoadingDisplay = () => {
        if (this.props.state.loadingDisplayFlag) {
            return(
                <div className="asc-block-ui">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{this.props.langText.Table.LoadingText}</label>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }

    render() {
        let {
            state,
        } = this.props;
        let result = this.getTitle("title");
        return (
            <Modal
                dialogClassName = {this.props.dialogClassName}
                scrollable = "true"
                size = "lg"
                aria-labelledby = "contained-modal-title-lg"
                state={state}
                show = {this.props.state.show}
            >
                <Form horizontal="true">
                    {this.modalLoadingDisplay()}
                    <Modal.Header>
                        <Modal.Title>
                            <Row>
                                <Col className="modal-title-text" lg={11} sm={11} xs={11}>
                                    {result.title}
                                </Col>
                                <Col lg={1} sm={1} xs={1}>
                                    <span>{this.getXBtn()}</span>
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Card variant="info">
                        <Card.Body>
                            <InlineForm
                                key = "name"
                                controlId = "name"
                                type = "text"
                                label = {this.props.langText.Body.CustomerName}
                                value = {this.props.state.customer_name}
                                onChange = {e => this.props.onTextChange(e)}
                            />
                        </Card.Body>
                        </Card>
                    </Modal.Body>

                    <Modal.Footer className="card-button">
                                <span>{this.getCancelBtn()}</span>
                                {
                                    state.modalType === "editCustomer"&&
                                    <span>{this.getDeleteBtn()}</span>
                                }
                                <span>{this.getOkBtn()}</span>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
