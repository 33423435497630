import React from 'react'
import Component from '../../components/AscComponent'
import {FormControl, Row, Col, InputGroup} from 'react-bootstrap'
import LoadingButton from '../Elements/Button/LoadingButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faLock} from "@fortawesome/free-solid-svg-icons";
import './SignIn.css'
import logo from "../../image/logo.png";

export default class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            userId: '',
            password: '',
        }
    }

    validationForm = () => {
        return this.state.userId.length > 0 && this.state.password.length > 0;
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({isLoading: true});
        let {userId, password} = this.state;
        try {
            let response = await this.ascAxios('post', '/Sign/login', {
                userId,
                password
            });
            if (response.status === 200) {
                this.props.userHasAuthenticated(true, response.data);
                this.props.historyPush('/');
            } else {
                alert(this.props.langText.Message.SignIn_SignInFailed);
                this.setState({isLoading: false });
            }
        } catch (err) {
            console.error(err)
            if(err.response && err.response.data.code !== 'UserNotConfirmedException'){
                this.showErrorObjectMessage('SignIn_SignInFailed');
            } else {
                this.showErrorObjectMessage(err,'SignIn_SignInFailed');
            }
            this.setState({isLoading: false});
        }
    }

    render() {
        return (
        <Row>
            <Col lg={5} sm={5}>
            <div className="signIn-logo-string"><img src={this.props.systemParameters.LOGO} className="img-logo" alt={this.props.systemParameters.SYSTEMTITLE}></img></div>
            </Col>
            <Col lg={7} sm={7} className='signIn-col'>
                <div className='signIn'>
                    <form className='form-block' onSubmit={this.handleSubmit}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser}/>
                            </InputGroup.Text>
                            <FormControl
                                autoFocus
                                type = 'text'
                                value = {this.state.userId}
                                onChange = {e => this.onTextChange(e, 'userId')}
                                placeholder = {this.props.langText.Body.UserIdLogIn}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faLock}/>
                            </InputGroup.Text>
                            <FormControl
                                value = {this.state.password}
                                onChange = {e => this.onTextChange(e, 'password')}
                                type = 'password'
                                placeholder = {this.props.langText.Body.Password}
                            />
                        </InputGroup>
                        <LoadingButton
                            className = 'signIn-button'
                            disabled = {!this.validationForm()}
                            type = 'submit'
                            isLoading = {this.state.isLoading}
                            text = {this.props.langText.Body.LogIn}
                            loadingText = {this.props.langText.Body.LogGingIn}
                        />
                    </form>
                </div>
            </Col>
        </Row>
        )
    }
}