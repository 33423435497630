import React from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import "./LoadingButton.css";

export default ({
                    isLoading,
                    text,
                    loadingText,
                    className = "",
                    disabled = false,
                    ...props
                }) =>
    <Button
        className={`LoadingButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <FontAwesomeIcon icon={faSyncAlt} className="spinning"/>}
        {!isLoading ? text : loadingText}
    </Button>;