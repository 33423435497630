/**
 * システムで利用する重要なグローバル定数群
 */

// コントローラー一覧 cm73_permissionsのjson_dataの順と合わせる必要がある
export const CONTROLLER_LIST = [
    "Record","User","Device","Permission",
];

// scope_code一覧
export const LEVEL_OBJECT = {
    system: {name: "system", value: 100},
    company: {name: "company", value: 200},
    user: {name: "user", value: 300},
};

// オプション機能一覧
export const OPTIONS = {
    audio2text: "AI録音文字起こし",
    customer_infos: "電話帳機能",
    RecAceApp: "RecACEアプリ"
};

// 設定値
export const VALUES = {
    audio2text_free_hour: 0.5,
    audio2text_inv_yen: 600,
    audio2text_inv_hour: 3
};