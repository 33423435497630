import React from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import Component from '../../components/AscComponent';
import CommonTable from '../../containers/Elements/Table/CommonTable';
import UserDeleteModal from './UserDeleteModal';
import UserInsertModal from './UserInsertModal';
import UserUpdateModal from './UserUpdateModal';
import UserReadModal from './UserReadModal';
import { CSVLink } from 'react-csv';

export default class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pageSize: 20,
            pages: null,
            loading: false,
            filtered: [],
            sorted: [{id: 'id', desc: true}],

            selectedData: [],

            // modal item
            show: false,
            modalType: null,
            password_change: false
        };

        this.onMultiSelectChange_External = this.onMultiSelectChange_External.bind(this);
        this.csvRef = React.createRef();
    }
    /**
     * 一覧表作成
     */
    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.UserId,
                accessor: 'user_id',
                width: this.props.boardWidth.smedium,
                Filter: () =>
                    <input
                        className="filter-input"
                        onChange={e => this.onFilterChange(e.target.value, "user_id")}
                    />

            }, {
                Header: this.props.langText.Body.Name,
                width: this.props.boardWidth.Name,
                Cell: data =>{
                    let family_name = data.row.original.family_name || '';
                    let first_name = data.row.original.first_name || '';
                    return family_name+'　'+first_name;
                },
                disableFilters: true
            }, {
                Header: this.props.langText.Body.MailAddress,
                accessor: 'mail_address',
                width: this.props.boardWidth.xlarge,
                Filter: () =>
                    <input
                        className="filter-input"
                        onChange={e => this.onFilterChange(e.target.value, "mail_address")}
                    />
            }, {
                Header: this.props.langText.Body.Telno,
                accessor: 'tel_number',
                width: this.props.boardWidth.CallNumber,
                Filter: () =>
                    <input
                        className="filter-input"
                        onChange={e => this.onFilterChange(e.target.value, "tel_number")}
                    />
            }, {
                Header: this.props.langText.Body.Permission,
                accessor: 'hm73_permission.name',
                width: this.props.boardWidth.smedium,
                disableFilters: true
            }, {
                Header: this.props.langText.Body.OwnDevice,
                accessor: 'hm20_devices.serial_number',
                width: this.props.boardWidth.smedium,
                filterable: true,
                Cell: data =>{
                    return data.row.original.hm20_devices.map(row=>{
                        return <div key={row.id}>{row.serial_number}</div>
                    })
                },
                Filter: () =>
                    <input
                        className="filter-input"
                        onChange={e => this.onFilterChange(e.target.value, "hm20_devices.serial_number")}
                    />
            }, {
                Header: this.props.langText.Body.Type,
                accessor: 'type',
                width: this.props.boardWidth.smedium,
                disableFilters: true,
                Cell: data =>{
                    return <div key={data.row.original.id + "_type"}>
                        {this.props.langText.Body[data.row.original.type]}
                    </div>
                },
            }
        ]
    }

    /**
     * 各ボタンから新しいmodal表示する際に使用する関数
     */
    modalShow = (modalType) => async event => {
        event.preventDefault();
        try{
            let user_id = "";
            let password = "";
            if (modalType === "insert") {
                let user_id_con = await this.ascAxios('post', `${this.reactContainerPath}/getUserId`);
                user_id = user_id_con.data.value;
                do {
                    password = Math.random().toString(36).substr(2, 8);
                } while (!this.validatePassword(password))
            }
            let deviceList, permissionList, externalList = [];
            let {selectedData} = this.state;

            if((modalType === "update" || modalType === "delete" || modalType === "read")&& selectedData.length === 0){
                //編集・削除の場合一つ以上の項目選択必須
                return this.showErrorObjectMessage('User_ModalSelectError')
            } else if(modalType === "update" && selectedData.length > 1){
                //編集場合一つの項目だけ選択可能
                return this.showErrorObjectMessage('User_UpdateModalSelectError')
            }
            let updateData = (modalType==="update" || modalType === "read") && selectedData.slice().pop();

            if(modalType === "insert" || modalType === "update" || modalType === "read"){
                deviceList = await this.getDeviceList(updateData && updateData.id, this.props.userInfo.company_id);
                permissionList = await this.getPermissionList(this.props.userInfo.company_id);
            }
            if(modalType === "update" || modalType === "read"){
                externalList = await this.getExternalNumberByUser(updateData && updateData.id)
            }

            let setData = {
                hm15_id:updateData && updateData.id
                    ? updateData.id
                    : 0,
                hm12_id:updateData && updateData.hm12_id
                    ? updateData.hm12_id
                    : this.props.userInfo.company_id,
                user_id: updateData && updateData.user_id
                    ? updateData.user_id
                    : user_id,
                family_name: updateData && updateData.family_name
                    ? updateData.family_name
                    : '',
                first_name: updateData && updateData.first_name
                    ? updateData.first_name
                    : '',
                mail_address: updateData && updateData.mail_address
                    ? updateData.mail_address
                    : '',
                tel_number: updateData && updateData.tel_number
                    ? updateData.tel_number
                    : '',
                password: modalType === 'update'
                    ? ''
                    : password,
                hm73_id: updateData && updateData.hm73_id
                    ? updateData.hm73_id
                    : 0,
                hm73_level: updateData && updateData.hm73_permission && updateData.hm73_permission.data
                && updateData.hm73_permission.data.Permission && updateData.hm73_permission.data.Permission.level
                    ? updateData.hm73_permission.data.Permission.level
                    : "",
                hm73_name: updateData && updateData.hm73_permission && updateData.hm73_permission.name
                    ? updateData.hm73_permission.name
                    : "",
                hm20_id: updateData && updateData.hm20_devices
                    ?  updateData.hm20_devices.map(row=> {return row.id})
                    : [],
                hm20_id_before: updateData && updateData.hm20_devices
                    ?  updateData.hm20_devices.map(row=> {return row.id})
                    : [],
                deviceList: deviceList,
                permissionList: permissionList,
                external_number: externalList,
                external_number_val: externalList.map(row=>{
                    return row.value
                }),
                type: updateData && updateData.type ? updateData.type : "Business",
                voices_expire: updateData && updateData.voices_expire ? updateData.voices_expire : 0,
                options: updateData && updateData.options ? updateData.options : {},
                modalType: modalType,
                password_change: false,
                show: true
            };

            if(modalType === "delete"){
                setData.hm15_id = selectedData.map(row=>row.id);
                setData.user_id = selectedData.map(row=>row.user_id);
                setData.hm20_id_check = selectedData.map(row=>{
                    let obj = {};
                    obj[row.id] = row.hm20_devices.map(device=> device.id);
                    return obj;
                })
                setData.isLoginedUser = setData.user_id.find(row=> row === this.props.userInfo.user_id)
                selectedData.map(row=>row.name = row.family_name+"　"+row.first_name)
            }

            this.setState(setData);
        }catch(err){
            console.error(err)
            this.showErrorObjectMessage(err);
        }
    };

    onMultiSelectChange_External (event) {
        if (Array.isArray(event)) {
            this.setState({
                external_number: event,
                external_number_val: event.map(row => {return row.value})
            });
        }
    }

    onClickHandle = modalType => async () => {
        let {
            user_id,
            family_name,
            first_name,
            mail_address,
            tel_number,
            external_number_val,
            password,
            hm73_id,
            hm12_id,
            hm15_id,
            hm20_id,
            hm20_id_before,
            hm20_id_check,
            isLoginedUser,
            type,
            options
        } = this.state;

        try {
            switch (modalType) {
                case 'insert':
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/insert`, {
                        user_id,
                        password,
                        family_name,
                        first_name,
                        mail_address,
                        tel_number,
                        external_number_val,
                        hm73_id,
                        hm20_id,
                        hm12_id,
                        type,
                        options
                    })
                    let headers = [
                        { label: this.props.langText.Body.UserId, key: "user_id" },
                        { label: this.props.langText.Body.Password, key: "password" },
                    ];
                    let data = [{ user_id, password}];
                    let DownloadData =
                        <CSVLink
                            headers = {headers}
                            data = {data}
                            className = "hidden"
                            filename = {user_id+this.props.langText.Body.UserInfo+".csv"}
                            ref = {this.csvRef}
                        />
                    this.setState({DownloadData});
                    this.csvRef.current.link.click();
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh(true);
                    break;
                case 'update':
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/update`, {
                        user_id,
                        hm15_id,
                        password,
                        family_name,
                        first_name,
                        mail_address,
                        tel_number,
                        external_number_val,
                        hm73_id,
                        hm20_id,
                        hm20_id_before,
                        options
                    })
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    this.reactTableRefresh(true);
                    break;
                case 'delete':
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/delete`, {
                        user_id,
                        hm15_id,
                        hm20_id_check,
                        isLoginedUser
                    })
                    alert(this.props.langText.Message.DataDeleteSuccess);
                    this.reactTableRefresh(true);
                    break;
                default:
                    this.showErrorObjectMessage('ModalTypeError');
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMessage(err);
        } finally {
            this.setState({show: false});
        }
    };

    /**
     * 各画面のボタン押下条件
     */
    validationHandle = param => {
        let validation_flag = false;
        let {
            user_id,
            hm73_id,
            password,
            tel_number,
            mail_address,
            isLoginedUser
        } = this.state;
        switch (param) {
            case 'insert':
            case 'update':
                validation_flag = !!(user_id && hm73_id&&
                    (password? this.validatePassword(password): true)&&
                    (tel_number? this.validateTelNumber(tel_number): true)&&
                    (mail_address? this.validateMail(mail_address): true));
                break;
            case 'read':
                validation_flag = !!(user_id);
                break;
            case 'delete':
                validation_flag = !!(user_id && !isLoginedUser);

                break;
            default:
                break;
        }
        return validation_flag;
    };

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case 'insert':
                return (
                    <UserInsertModal
                        state = {this.state}
                        userInfo={this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onMultiSelectChange_External = {this.onMultiSelectChange_External}
                        validationHandle = {this.validationHandle}
                        validateMail = {this.validateMail}
                        validateTelNumber = {this.validateTelNumber}
                    />
                )
            case 'update':
                return (
                    <UserUpdateModal
                        state = {this.state}
                        userInfo={this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange = {this.onTextChange}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onMultiSelectChange_External = {this.onMultiSelectChange_External}
                        validateMail = {this.validateMail}
                        validateTelNumber = {this.validateTelNumber}
                        validatePassword = {this.validatePassword}
                        validationHandle = {this.validationHandle}
                    />
                )
            case 'delete':
                return (
                    <UserDeleteModal
                        state = {this.state}
                        userInfo={this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            case 'read':
                return (
                    <UserReadModal
                        state = {this.state}
                        userInfo={this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            default:
                break
        }
    }

    render() {
        return (
            <div className='User'>
                {/*一覧画面*/}
                <Row>
                    <Col xs={12} md={12}>

                        <Button
                            id = 'user-insert'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('insert')}
                            hidden={!this.props.currentPermission.create}
                        >
                            {this.props.langText.Body.InsertTitle}
                        </Button>
                        <Button
                            id = 'user-update'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('update')}
                            hidden={!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.UpdateTitle}
                        </Button>
                        <Button
                            id = 'user-read'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('read')}
                            hidden={!!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.ReadTitle}
                        </Button>
                        <Button
                            id = 'user-delete'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('delete')}
                            hidden={!this.props.currentPermission.delete}
                        >
                            {this.props.langText.Body.DeleteTitle}
                        </Button>
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            state = {this.state}
                            tableHeight={this.props.tableHeight}
                            tbodyHeight={this.props.tableHeight-30}
                            propsPageIndex = {this.state.pageIndex? this.state.pageIndex: 0}
                            columns={this.state.columns}
                            data={this.state.data}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            sorted={this.state.sorted}
                            pageCount = {this.state.pageCount}
                            manual = {true}
                            showPagination={true}
                            showCheckbox={true}
                            showPageSize = {true}
                            showFilter = {true}
                            langText = {this.props.langText.Table}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
