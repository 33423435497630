import React from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import Component from '../../components/AscComponent';
import CommonTable from '../../containers/Elements/Table/CommonTable';
import DeviceDeleteModal from './DeviceDeleteModal';
import DeviceUpdateModal from './DeviceUpdateModal';
import DeviceReadModal from './DeviceReadModal';

export default class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pageSize: 20,
            pages: null,
            loading: false,
            filtered: [],
            sorted: [{id: 'id', desc: true}],

            selectedData: [],

            // modal item
            show: false,
            modalType: null
        };
    }
    /**
     * 一覧表作成
     */
    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.SerialNumber,
                accessor: 'serial_number',
                width: this.props.boardWidth.small,
                Filter: () => 
                <input 
                    className="filter-input"
                    onChange={e => this.onFilterChange(e.target.value, "serial_number")}
                />
            }, {
                Header: this.props.langText.Body.OwnerUserId,
                width: this.props.boardWidth.smedium,
                accessor: 'hm15_user.user_id',
                Cell: ({value}) => value || '---',
                Filter: () => 
                <input 
                    className="filter-input"
                    onChange={e => this.onFilterChange(e.target.value, "hm15_users.user_id")}
                />
            }, {
                Header: this.props.langText.Body.OwnerUserName,
                width: this.props.boardWidth.Name,
                Cell: data =>{
                    let family_name = data.row.original.hm15_user? data.row.original.hm15_user.family_name: '';
                    let first_name = data.row.original.hm15_user? data.row.original.hm15_user.first_name: '';
                    return family_name||first_name? family_name+'　'+first_name: '---';
                },
                disableFilters: true
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: 'description',
                width: this.props.boardWidth.medium,
                Cell: ({value}) => value || '---',
                disableFilters: true
            }, {
                Header: this.props.langText.Body.Created,
                accessor: 'created',
                width: this.props.boardWidth.Date,
                Cell: ({value}) => value
                    ? this.getMomentTime({date: value})
                    : '',
                disableFilters: true
            }, {
                Header: this.props.langText.Body.LatestActive,
                accessor: 'ht22_device_active_history.created',
                width: this.props.boardWidth.Date,
                Cell: ({value}) => value
                    ? this.getMomentTime({date: value, format: 'YYYY-MM-DD HH:mm'})
                    : '---',
                disableFilters: true
            }
        ]
    }

    /**
     * 各ボタンから新しいmodal表示する際に使用する関数
     */
    modalShow = (modalType) => async event => {
        event.preventDefault();
        try{
            let {selectedData} = this.state;

            //編集・削除の場合一つ以上の項目選択必須
            if((modalType === "update" || modalType === "read" || modalType === "delete" )&& selectedData.length === 0){
                return this.showErrorObjectMessage('ModalSelectError');
            }
            //編集場合一つの項目だけ選択可能
            if((modalType === "update" || modalType === "read") && selectedData.length > 1){
                return this.showErrorObjectMessage('UpdateModalSelectError');
            }
            let updateData = (modalType === "update"  || modalType === "read") && selectedData.slice().pop();

            let setData = {
                hm20_id:updateData && updateData.id
                    ? updateData.id
                    : 0,
                serial_number:updateData && updateData.serial_number
                    ? updateData.serial_number
                    : '',
                owner_user_id:updateData && updateData.hm15_user
                    ? updateData.hm15_user.user_id
                    : '---',
                owner_family_name: updateData && updateData.hm15_user && updateData.hm15_user.family_name
                    ? updateData.hm15_user.family_name
                    : '---',
                owner_first_name: updateData && updateData.hm15_user && updateData.hm15_user.first_name
                    ? updateData.hm15_user.first_name
                    : '---',
                created: updateData && updateData.created
                    ? updateData.created
                    : '',
                lastest_active: updateData && updateData.ht22_device_active_history
                    ? updateData.ht22_device_active_history.created
                    : '',
                description: updateData && updateData.description
                    ? updateData.description
                    : '',
                modalType: modalType,
                show: true
            };

            if(modalType === "delete"){
                setData.hm20_id = selectedData.map(row=>row.id);
                setData.isOwnedDevice = selectedData.some(row=> row.hm15_user && row.hm15_user.user_id);
            }
            this.setState(setData);
        } catch(err){
            console.error(err);
            this.showErrorObjectMessage(err);
        }
    };

    onClickHandle = modalType => async () => {
        let {
            hm20_id,
            description
        } = this.state;
        try {
            switch (modalType) {
                case 'update':
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/update`, {
                        hm20_id,
                        description
                    })
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    this.reactTableRefresh(true);
                    break;
                case 'delete':
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/delete`, {
                        hm20_id
                    })
                    alert(this.props.langText.Message.DataDeleteSuccess);
                    this.reactTableRefresh(true);
                    break;
                default:
                    this.showErrorObjectMessage('ModalTypeError');
            }
        } catch (err) {
            this.showErrorObjectMessage(err);
        } finally {
            this.setState({show: false});
        }
    };

    /**
     * 各画面のボタン押下条件
     */
    validationHandle = param => {
        let validation_flag = false;
        let {
            hm20_id,
            isOwnedDevice
        } = this.state;
        switch (param) {
            case 'update':
                validation_flag = !!(hm20_id);
                break;
            case 'delete':
                validation_flag = !!(hm20_id && !isOwnedDevice);
                break;
            default:
                break;
        }
        return validation_flag;
    };

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case 'update':
                return (
                    <DeviceUpdateModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange = {this.onTextChange}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        getMomentTime = {this.getMomentTime}
                        validationHandle = {this.validationHandle}/>
                )
            case 'delete':
                return (
                    <DeviceDeleteModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            case 'read':
                return (
                    <DeviceReadModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        getMomentTime = {this.getMomentTime}/>
                )
            default:
                return null;
        }
    }

    render() {
        return (
            <div className='Device'>
                {/*一覧画面*/}
                <Row>
                    <Col xs={12} md={12}>
                        <Button
                            id = 'device-update'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('update')}
                            hidden={!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.UpdateTitle}
                        </Button>
                        <Button
                            id = 'user-read'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('read')}
                            hidden={!!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.ReadTitle}
                        </Button>
                        <Button
                            id = 'device-delete'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('delete')}
                            hidden={!this.props.currentPermission.delete}
                        >
                            {this.props.langText.Body.DeleteTitle}
                        </Button>
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            state = {this.state}
                            tableHeight={this.props.tableHeight}
                            tbodyHeight={this.props.tableHeight-30}
                            propsPageIndex = {this.state.pageIndex? this.state.pageIndex: 0}
                            columns={this.state.columns}
                            data={this.state.data}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            sorted={this.state.sorted}
                            pageCount = {this.state.pageCount}
                            manual = {true}
                            showPagination={true}
                            showCheckbox={true}
                            showPageSize = {true}
                            showFilter = {true}
                            langText = {this.props.langText.Table}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
