import React from "react";
import {Button, Row, Col} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import PermissionDeleteModal from "./PermissionDeleteModal";
import PermissionInsertModal from "./PermissionInsertModal";
import PermissionUpdateModal from "./PermissionUpdateModal";
import PermissionReadModal from "./PermissionReadModal";
import * as GlobalConst from '../../components/AscConstants';

export default class Permission extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            loading: false,
            filtered: [],
            sorted: [{id: "id", desc: true}],

            // modal item
            show: false,
            modalType: null,
            companySelect: [],

            selectedData: [],
        };
    }
    

    /**
     * 一覧表作成
     */
    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.PermissionName,
                accessor: "name",
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
            }, {
                Header: this.props.langText.Body.UserCount,
                width: this.props.boardWidth.xxsmall,
                accessor: "hm15_users.user_count",
                Cell: data => {
                    if (data.row.original.hm15_user) {
                        return data.row.original.hm15_user.user_count
                    } else {
                        return 0
                    }
                },
            }, {
                Header: this.props.langText.Body.UpdateUserName,
                accessor: "update_user_name",
                width: this.props.boardWidth.Name,
                Cell: data => {
                    let update_users_info = data.row.original.update_users;

                    if (update_users_info) {
                        return update_users_info.family_name + " " + update_users_info.first_name;
                    } else {
                        return null
                    }
                },
            }, {
                Header: this.props.langText.Body.Modified,
                accessor: "modified",
                width: this.props.boardWidth.Date,
                Cell: ({value}) => value ?
                    this.getMomentTime({format: this.props.langText.Body.DateFormat, date: value}) : "",
            }
        ]
    }


    /**
     * 各ボタンから新しいmodal表示する際に使用する関数
     */
    modalShow = (modalType) => event => {
        let { selectedData } = this.state;

        event.preventDefault();
        let rowInfo = '';
        //ひとつだけ選択して編集または削除ボタンを押した場合
        if (modalType === "update" || modalType === "read" || (modalType === "delete" && selectedData.length === 1)) {
            rowInfo = selectedData.slice().pop();
        } 
        
        /**
         * stateに追加
         */
        //編集・削除の場合一つ以上の項目選択必須
        if((modalType === "update" || modalType === "read" || modalType === "delete" )&& selectedData.length === 0){
            return this.showErrorObjectMessage('ModalSelectError');
        }
        //編集場合一つの項目だけ選択可能
        if((modalType === "update" || modalType === "read") && selectedData.length > 1){
            return this.showErrorObjectMessage('UpdateModalSelectError');
        }

        let setData = {
            permission_id: rowInfo && rowInfo.id
            ? rowInfo.id
            : 0,
            permission_name: rowInfo && rowInfo.name
            ? rowInfo.name
            : "",
            permission_memo: rowInfo && rowInfo.memo
            ? rowInfo.memo
            : "",
            user_count: rowInfo && rowInfo.hm15_user && rowInfo.hm15_user.user_count
            ? rowInfo.hm15_user.user_count
            : 0,
            registered_user_flag: rowInfo && rowInfo.hm15_user && rowInfo.hm15_user.user_count > 0,
            modalType: modalType,
            show: true
        }

        if(modalType === "delete" && selectedData.length > 1){
            setData.permission_id = selectedData.map(row=>row.id);
            setData.permission_name = selectedData.map(row=>row.name);
            let user_count_arr = [];
            selectedData.forEach(row => {
                if (row.hm15_user && row.hm15_user.user_count){
                    user_count_arr.push(row.hm15_user.user_count);
                    if(row.hm15_user.user_count > 0){
                        setData.registered_user_flag = true;
                    }
                }
                setData.user_count = user_count_arr;
            });
        }
        /**
         * stateに権限詳細を追加
         */
        GlobalConst.CONTROLLER_LIST.forEach((row, index) => {
            if (rowInfo && rowInfo.data[row]) {
                setData[row] = rowInfo.data[row];
            } else {
                setData[row] = this.getPermissionReset(GlobalConst.CONTROLLER_LIST[row]);
            }
        })

        this.setState(setData);
    };

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => event => {
        let {
            permission_id,
            permission_name,
            permission_memo,
            user_count
        } = this.state;

        let permissionList = {};
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            permissionList[row] = this.state[row];
        })

        switch (modalType) {
            case "insert":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/insert`, {
                        permission_id,
                        permission_name,
                        permission_memo,
                        permissionList
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh(true);
                    })
                    .catch(err => {
                        console.error(err)
                        this.showErrorObjectMessage(err,'DataInsertError');
                    });
                this.setState({show: false})
                break
            case "update":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/update`, {
                        permission_id,
                        permission_name,
                        permission_memo,
                        permissionList
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh(true);
                    })
                    .catch(err => {
                        console.error(err)
                        this.showErrorObjectMessage(err,'DataUpdateError');
                    })
                this.setState({show: false})
                break
            case "delete":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/delete`, {
                        permission_id,
                        user_count
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh(true);
                    })
                    .catch(err => {
                        console.error(err)
                        this.showErrorObjectMessage(err,'DataDeleteError');
                    })
                this.setState({show: false})
                break
            default:
                alert(this.showErrorObjectMessage('ModalTypeError'));
        }
    };

    /**
     * 各画面のボタン押下条件
     */
    validationHandle = param => {
        let validation_flag = false;

        switch (param) {
            case "insert":
            case "update":
                validation_flag = !!(this.state.permission_name);
                break
            case "delete":
                validation_flag = !!(this.state.permission_id && this.state.user_count === 0);
                break
            case "read":
            default:
                break
        }

        return validation_flag;
    };

    /**
     * permissionData 初期化
     */
    getPermissionReset(controller_id) {
        let obj = {};

        obj[controller_id] = {
            level: 'user', 
            create: false, 
            read: false, 
            update: false, 
            delete: false,
            download: false, 
            playback: false
        }

        return obj[controller_id];
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "delete":
                return (
                    <PermissionDeleteModal
                        state = {this.state}
                        permission = {this.props.currentPermission}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            case "insert":
                return (
                    <PermissionInsertModal
                        state = {this.state}
                        permission = {this.props.currentPermission}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onNestSetState = {this.onNestSetState}
                        onNestCheckBoxChange = {this.onNestCheckBoxChange}
                        validationHandle = {this.validationHandle}/>
                )
            case "update":
                return (
                    <PermissionUpdateModal
                        state = {this.state}
                        permission = {this.props.currentPermission}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onNestSetState = {this.onNestSetState}
                        onNestCheckBoxChange = {this.onNestCheckBoxChange}
                        validationHandle = {this.validationHandle}/>
                )
            case "read":
                return (
                    <PermissionReadModal
                        state = {this.state}
                        permission = {this.props.currentPermission}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            default:
                break
        }
    }

    render() {
        return (
            <div className="Permission">
                {/*一覧画面*/}
                <Row>
                    <Col xs={12} md={12}>
                        <Button
                            id = "permission-insert"
                            className = "table-button"
                            variant = "default"
                            size = "sm"
                            onClick={this.modalShow("insert")}
                            hidden={!this.props.currentPermission.create}
                        >
                            {this.props.langText.Body.InsertTitle}
                        </Button>
                        <Button
                            id = "permission-update"
                            className = "table-button"
                            variant = "default"
                            size = "sm"
                            onClick={this.modalShow("update")}
                            hidden={!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.UpdateTitle}
                        </Button>
                        <Button
                            id = 'user-read'
                            className = 'table-button'
                            variant = 'default'
                            size = 'sm'
                            onClick={this.modalShow('read')}
                            hidden={!!this.props.currentPermission.update}
                        >
                            {this.props.langText.Body.ReadTitle}
                        </Button>
                        <Button
                            id = "permission-delete"
                            className = "table-button"
                            variant = "default"
                            size = "sm"
                            onClick={this.modalShow("delete")}
                            hidden={!this.props.currentPermission.delete}
                        >
                            {this.props.langText.Body.DeleteTitle}
                        </Button>
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            showPageSize={true}
                            showCheckbox={true}
                            showPagination={true}
                            propsPageIndex = {this.state.pageIndex? this.state.pageIndex: 0}
                            state = {this.state}
                            tableHeight={this.props.tableHeight}
                            tbodyHeight={this.props.tableHeight-30}
                            columns={this.state.columns}
                            data={this.state.data}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            sorted={this.state.sorted}
                            pageCount = {this.state.pageCount}
                            manual = {true}
                            langText = {this.props.langText.Table}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
