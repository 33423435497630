import React from "react";
import {Row, Col, Form} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import * as GlobalConst from "../../../components/AscConstants";
import CreatableSelect from 'react-select/creatable';
import {faEdit, faXmark} from "@fortawesome/free-solid-svg-icons";
import "./UserElements.css";
import ButtonSpeechBubble from "./SetButtonWithSpeechBubble";

/**
 * ユーザーIDアイテム
 * state.user_idが必要
 */
export class ViewUserId extends Component {
    render() {
        return(
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.UserId}
                readOnly
                children={this.props.state.user_id}>
            </InlineForm>
        )
    }
}

/**
 * パスワードビューアイテム
 * state.passwordが必要
 */
export class ViewPassword extends Component {
    render() {
        return(
            <InlineForm
                key="password"
                controlId="password"
                label={this.props.langText.Body.Password}
                readOnly
                children={this.props.state.password}
            />
        )
    }
}

/**
 * 削除項目ビューアイテム
 * state.selectedDataが必要
 */
export class ViewDeleteUserList extends Component {
    render(){
        return(
            <>
            {this.props.state.isLoginedUser&&
            <p className="validation-message">
                {this.props.state.isLoginedUser}{this.props.langText.Message.User_IsLoginedUserError}
            </p>}
            <CommonTable
                className = "none-overflow"
                label = {this.props.langText.Body.PermissionName}
                showPagination = {true}
                defaultPageSize = {10}
                columns = {[{
                    Header :this.props.langText.Body.UserId,
                    accessor: "user_id",
                },
                {
                    Header :this.props.langText.Body.Name,
                    accessor: "name",
                }]}
                data = {this.props.state.selectedData}
            />
            </>
        );
    }
}

/**
 * ユーザー名入力アイテム
 * state.family_name, state.first_nameが必要
 */
export class EditUserName extends Component {
    render() {
        return (
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.Name}
                readOnly
            >
                <Row>
                    <Col className = "user_name">
                        <InlineForm
                            key = "family_name"
                            controlId = "family_name"
                            label = {this.props.langText.Body.FamilyName}
                            type = "text"
                            value = {this.props.state.family_name}
                            onChange = {e => this.props.onTextChange_Limit(
                                e, "family_name",this.props.charaLimit.User_username)}
                        />
                    </Col>
                    <Col className = "user_name">
                        <InlineForm
                            key = "first_name"
                            controlId = "first_name"
                            label = {this.props.langText.Body.FirstName}
                            type = "text"
                            value = {this.props.state.first_name}
                            onChange = {e => this.props.onTextChange_Limit(
                                e, "first_name",this.props.charaLimit.User_username)}
                        />
                    </Col>
                </Row>
            </InlineForm>
        );
    }
}

/**
 * ユーザー名表示アイテム
 * state.family_name, state.first_nameが必要
 */
export class ViewUserName extends Component {
    render() {
        let str = this.props.state.family_name + " " + this.props.state.first_name;
        return (
            <InlineForm
                key="user_id"
                controlId="user_id"
                label={this.props.langText.Body.Name}
                readOnly>
                {str.length > 1 ? str : "---"}
            </InlineForm>
        );
    }
}

/**
 * パスワード入力アイテム
 * state.passwordが必要
 */
export class EditPassword extends Component {

    validatePassword(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validatePassword(param);
        }
        return validation_flag;
    }
    render() {
        if (!this.props.state.hm73_level || (this.props.state.hm73_level
            && GlobalConst.LEVEL_OBJECT[this.props.userInfo.permission_data.Permission.level].value
            <= GlobalConst.LEVEL_OBJECT[this.props.state.hm73_level].value)) {
            if (this.props.state.password_change) {
                return(
                    <InlineForm
                        key="password"
                        controlId="password"
                        label={this.props.langText.Body.Password}
                        readOnly
                    >
                        <div className={"user_password_change"}>
                            <ButtonSpeechBubble
                                key = {"user_password_cancel-button"}
                                size="sm"
                                className="user_password_cancel-button"
                                DisplayIcon={faXmark}
                                speechBubble={this.props.langText.Body.PasswordChangeCancel}
                                onClick={() => this.props.propSetState({
                                    password_change: false,
                                    password: null
                                })}
                            />
                            <InlineForm
                                key = "password"
                                controlId = "password"
                                type = "password"
                                value = {this.props.state.password}
                                onChange = {e => this.props.onTextChange(e, "password")}
                                placeholder = {this.props.langText.Body.PasswordPlaceholder}
                                isInvalid = {!this.validatePassword(this.props.state.password,true)}
                            />
                        </div>
                    </InlineForm>
                );
            } else {
                return(
                    <div>
                        <InlineForm
                            key = "password"
                            controlId = "password"
                            label = {this.props.langText.Body.Password}
                            readOnly>
                            <ButtonSpeechBubble
                                key = {"user_password_change-button"}
                                size="sm"
                                className="user_password_change-button"
                                DisplayIcon={faEdit}
                                speechBubble={this.props.langText.Body.PasswordChange}
                                onClick={() => this.props.propSetState({password_change: true})}
                            />
                        </InlineForm>
                    </div>
                );
            }
        } else {
            return(
                <InlineForm
                    key = "password"
                    label = {this.props.langText.Body.Password}
                    readOnly
                    value = {""}
                />
            );
        }
    }
}

/**
 * メールアドレス入力アイテム
 * state.mail_addressが必要
 */
export class EditMailAddress extends Component {
    validateMail(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateMail(param);
        }
        return validation_flag;
    }

    render(){
        return(
            <InlineForm
                key = "mail_address"
                label = {this.props.langText.Body.MailAddress}
                type = "mail"
                value = {this.props.state.mail_address}
                onChange = {e => this.props.onTextChange_Limit(
                    e, "mail_address",this.props.charaLimit.User_mail_address)}
                isInvalid = {!this.validateMail(this.props.state.mail_address,true)}/>
        );
    }
}

/**
 * メールアドレス表示アイテム
 * state.mail_addressが必要
 */
export class ViewMailAddress extends Component {
    render(){
        return(
            <InlineForm
                key = "mail_address"
                controlId = "mail_address"
                label = {this.props.langText.Body.MailAddress}
                readOnly>
                {this.props.state.mail_address || "---"}
            </InlineForm>
        );
    }
}

/**
 * 電話番号入力アイテム
 * state.tel_numberが必要
 */
export class EditTelNum extends Component {
    validateTelNumber(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateTelNumber(param);
        }
        return validation_flag;
    }
    render(){
        return(
            <InlineForm
                key = "tel_number"
                label = {this.props.langText.Body.Telno}
                type = "tel_number"
                value = {this.props.state.tel_number}
                onChange = {e => this.props.onTextChange_Limit(
                    e, "tel_number", this.props.charaLimit.User_tel_number)}
                isInvalid = {!this.validateTelNumber(this.props.state.tel_number,true)}/>
        );
    }
}

/**
 * 電話番号表示アイテム
 * state.tel_numberが必要
 */
export class ViewTelNum extends Component {
    render(){
        return(
            <InlineForm
                key = "tel_number"
                controlId = "tel_number"
                label = {this.props.langText.Body.Telno}
                readOnly>
                {this.props.state.tel_number || "---"}
            </InlineForm>
        );
    }
}

/**
 * 電話番号入力アイテム
 * state.external_numberが必要
 */
export class EditExternalNum extends Component {
    render(){
        return(
            <InlineForm
                key = "external_number"
                controlId = "external_number"
                label = {this.props.langText.Body.ExternalNumber}
            >
                <CreatableSelect
                    isMulti
                    onChange = {e => this.props.onMultiSelectChange_External(e)}
                    placeholder = {this.props.langText.Message.Validation_Telno}
                    isClearable
                    value={this.props.state.external_number}
                />
            </InlineForm>
        )
    }
}

/**
 * 電話番号表示アイテム
 * state.external_numberが必要
 */
export class ViewExternalNum extends Component {
    render(){
        let str = "";
        if (this.props.state.external_number.length) {
            this.props.state.external_number.forEach(row => {
                str += row.value + ", "
            });
            str = str.slice(0,str.length - 2);
        } else {
            str = "---";
        }
        return(
            <InlineForm
                key = "external_number"
                controlId = "external_number"
                label = {this.props.langText.Body.ExternalNumber}>
                {str}
            </InlineForm>
        )
    }
}

/**
 * 権限選択アイテム
 * state.hm73_id, state.permissionListが必要
 */
export class SelectPermission extends Component {
    render(){
        if (!this.props.state.hm73_level || (this.props.state.hm73_level
            && GlobalConst.LEVEL_OBJECT[this.props.userInfo.permission_data.Permission.level].value
            <= GlobalConst.LEVEL_OBJECT[this.props.state.hm73_level].value)) {
            return(
                <InlineForm
                    key = "hm73_id"
                    label = {this.props.langText.Body.Permission}
                    type = "select"
                    options = {this.props.state.permissionList}
                    value = {this.props.state.hm73_id}
                    onChange = {e => this.props.onSelectChange(e, "hm73_id")}
                    placeholder = {this.props.langText.Message.Validation_Select_input}
                    required
                />
            );
        } else {
            return(
                <InlineForm
                    key = "hm73_id"
                    label = {this.props.langText.Body.Permission}
                    readOnly
                    value = {this.props.state.hm73_name}
                    required
                />
            );
        }
    }
}

/**
 * 権限選択アイテム
 * state.hm73_id, state.permissionListが必要
 */
export class ViewPermission extends Component {
    render(){
        return(
            <InlineForm
                key = "hm73_id"
                controlId = "hm73_id"
                label = {this.props.langText.Body.Permission}
                readOnly>
                {this.props.state.hm73_name}
            </InlineForm>
        );
    }
}

/**
 * デバイス選択アイテム
 * state.hm20_id, state.deviceListが必要
 */
export class SelectDevice extends Component {
    render(){
        return(
            <InlineForm
                key = "hm20_id"
                label = {this.props.langText.Body.Device}
                type = "select"
                isMulti
                closeMenuOnSelect={false}
                options = {this.props.state.deviceList}
                value = {this.props.state.hm20_id}
                onChange = {e => this.props.onMultiSelectChange(e, "hm20_id")}
                placeholder = {this.props.langText.Message.Validation_Select_input}
                isInvalid = {this.props.state.hm73_id ? null : "error"}
                />
        );
    }
}

/**
 * オプションアイテム
 */
export class SelectOptions extends Component {
    getOptionsElements() {
        return Object.entries(GlobalConst.OPTIONS).sort().map(row => {
            return <row className="options-check-row">
                <Form.Check
                    className="options-check"
                    key = {row[0]}
                    controlId = {row[0]}
                    checked={!!this.props.state.options[row[0]]}
                    onChange={() => {
                        this.props.propSetState({
                            options: {
                                ...this.props.state.options,
                                [row[0]]: !this.props.state.options[row[0]]
                            }
                        })
                    }}/>
                <div className="options-check-description">
                    {row[1]}
                </div>
            </row>;
        });
    }
    render(){
        return (<InlineForm
            controlId = "options"
            key = "options"
            label = {this.props.langText.Body.Options}>
            {this.getOptionsElements()}
        </InlineForm>);
    }
}

/**
 * デバイス選択アイテム
 * state.hm20_id, state.deviceListが必要
 */
export class ViewDevice extends Component {
    render(){
        let str = "---";
        for (let i = 0; i < this.props.state.hm20_id.length; i++) {
            if (i === 0) {
                str = "";
            } else {
                str +=", "
            }
            let tmp = this.props.state.deviceList.find(item => item.value === this.props.state.hm20_id[i]);
            str += tmp && tmp.label ? tmp.label : "";
        }
        return(
            <InlineForm
                key = "hm20_id"
                controlId = "hm20_id"
                label = {this.props.langText.Body.Device}>
                {str}
            </InlineForm>
        );
    }
}

/**
 * 種別表示アイテム
 * state.typeが必要
 */
export class ViewType extends Component {
    render() {
        return(
            <InlineForm
                key="type"
                controlId="type"
                label={this.props.langText.Body.Type}
                readOnly
            >
                {this.props.langText.Body[this.props.state.type] || "---"}
            </InlineForm>
        )
    }
}

/**
 * 種別選択アイテム
 * state.typeが必要
 */
export class SelectType extends Component {
    render() {
        return(
            <InlineForm
                key = "type"
                label = {this.props.langText.Body.Type}
                type = "select"
                options = {this.getSelectOption('user_type', this.props.langText.SelectOption)}
                value = {this.props.state.type}
                onChange = {e => this.props.onSelectChange(e, "type")}
                required
            />
        )
    }
}

/**
 * 録音保持期限表示アイテム
 * state.voices_expireが必要
 */
export class ViewExpire extends Component {
    render() {
        let value;
        switch (true) {
            case this.props.state.voices_expire === 183:
                value = this.props.langText.Body.HalfYear;
                break;
            case this.props.state.voices_expire < 365:
                value = this.props.state.voices_expire + " " + this.props.langText.Body.Day
                break;
            case this.props.state.voices_expire >= 365:
                value = Math.floor(this.props.state.voices_expire / 365) + " " + this.props.langText.Body.Year;
                if (this.props.state.voices_expire % 365) {
                    value += " " + this.props.state.voices_expire % 365 + " " + this.props.langText.Body.Day
                }
                break;
            default:
                value = "---"
                break;
        }

        return(
            <InlineForm
                key="voices_expire"
                controlId="voices_expire"
                label={this.props.langText.Body.VoicesExpire}
                readOnly
            >
                {value}
            </InlineForm>
        )
    }
}